import { Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SearchItem } from './search-item';
import { SearchItemDirective } from './search-item-directive';
import { faTimesCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { MatSelectionList } from '@angular/material/list';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @ViewChild('input') input;
  @ViewChild('selectionList') selectionList: MatSelectionList;
  @ContentChildren(SearchItemDirective) public detailComponents: QueryList<SearchItemDirective>;

  faCircle = faTimesCircle;
  faSearch = faSearch;

  // tslint:disable-next-line: variable-name
  private _items: SearchItem[];

  @Input() set searchItems(newItems: SearchItem[]) {
    this._items = newItems;
    this.subscribeToSearch();
    this.search();
  }

  get inactiveItems(): SearchItem[] {
    return this._items.filter((i) => i.data?.active !== undefined ? !i.data.active : false);
  }

  get activeItems(): SearchItem[] {
    return this._items.filter((i) => i.data?.active !== undefined ? i.data.active : true);
  }

  get itemCount(): number {
    return this._items?.length || 0;
  }

  @Input() title = '';
  @Input() showAdd = false;
  @Input() loading = false;
  @Input() collapseOnSelect = false;
  @Input() warning = false;

  @Output() itemSelected = new EventEmitter<any>();
  @Output() newStart = new EventEmitter<void>();
  @Output() newCancel = new EventEmitter<void>();

  filteredActiveItems: SearchItem[];
  filteredInactiveItems: SearchItem[];

  selectedItem: SearchItem;
  searchText = '';
  creatingNew = false;
  showSearch = true;
  searchSubscription: Subscription;

  constructor() { }

  ngOnInit(): void {}

  selectItem(item: SearchItem) {
    this.selectedItem = item;
    this.creatingNew = false;
    if (!(this.detailComponents?.length > 0)) {
      setTimeout(() => this.selectDetailItem(item), 250);
    } else {
      this.selectDetailItem(item);
    }
    if (this.collapseOnSelect) {
      this.showSearch = false;
    }
  }

  subscribeToSearch() {
    setTimeout(() => this.applyInputSubscription(), 100);
  }

  applyInputSubscription() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    this.searchSubscription = this.input?.valueChanges
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .subscribe((value) => {
        this.searchText = value;
        this.search();
      });
  }

  selectDetailItem(item: SearchItem) {
    for (let idx = 0; idx < this.detailComponents.length; idx++ ) {
      this.detailComponents.get(idx).component.setItem(item);
    }
    this.itemSelected.emit(this.selectedItem);
    setTimeout(() => {
      const selectedMatOption = this.selectionList?.options.find((i) => i.value.data._id === this.selectedItem?.data._id);
      if (selectedMatOption) {
        selectedMatOption.focus();
      }
    }, 250);
  }

  search() {
    if (!(this.searchText.trim())) {
      this.updateItemLists();
    } else {
      this.filteredActiveItems = this.activeItems.filter((i) => i.label.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1);
      this.filteredInactiveItems = this.inactiveItems.filter((i) => i.label.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1);
    }
  }

  clearSearch() {
    this.searchText = '';
  }

  updateItemLists() {
    if (this._items) {
      this.filteredActiveItems = this.activeItems;
      this.filteredInactiveItems = this.inactiveItems;
    } else {
      this.filteredActiveItems = new Array<SearchItem>();
      this.filteredInactiveItems = new Array<SearchItem>();
    }
  }

  new() {
    this.creatingNew = true;
    this.newStart.emit();
    for (let idx = 0; idx < this.detailComponents.length; idx++ ) {
      this.detailComponents.get(idx).component.newItem();
    }
  }

  cancelNew() {
    this.creatingNew = false;
    setTimeout(() => {
      this.selectDetailItem(null);
      this.newCancel.emit();
    }, 20);
  }

  isItemSelected(item: SearchItem): boolean {
    return (item === this.selectedItem );
  }

  clearSelection() {
    this.selectedItem = null;
    this.showSearch = true;
    this.selectionList?.deselectAll();
    this.selectDetailItem(null);
    this.subscribeToSearch();
  }

  showSearchClicked() {
    this.clearSelection();
  }
}
