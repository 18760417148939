<div class="container">
    <div class="header">
        <div>{{data.title}}</div>
        <fa-icon *ngIf="data.showClose" [icon]="faTimesCircle" primaryColor="white" (click)="dismiss()"></fa-icon>
    </div>
    <div class="content">
        <div class="input-form">
            <div class="input-row">
                <div>{{data.message}}</div>
            </div>
        </div>
    </div>
    <div class="footer">
        <button mat-flat-button *ngFor="let option of data.options"
            [color]="option.style === buttonStyle.Cancel ? 'primary' : 'secondary'"
            [class.success]="option.style === buttonStyle.Confirm"
            (click)="selectOption(option)">
            {{option.name}}
        </button>
    </div>
</div>