import { Component, OnInit } from '@angular/core';
import { SearchDetailComponent, SearchInterfaceToken } from '../search-interface';
import { SearchItem } from '../search-item';

@Component({
  selector: 'app-sample-search',
  templateUrl: './sample-search.component.html',
  styleUrls: ['./sample-search.component.scss'],
  providers: [{
    provide: SearchInterfaceToken, useExisting: SampleSearchComponent
  }]
})
export class SampleSearchComponent extends SearchDetailComponent implements OnInit {
  item: SearchItem;
  constructor() {
    super();
  }

  newItem() {
    throw new Error('Method not implemented.');
  }

  canSave(): boolean {
    return true;
  }

  setItem(item: any): void {
    this.item = item;
  }

  ngOnInit(): void {
  }
}
