<div class="container">
    <div class="header">
        Change Password
    </div>
    <div class="content">
        <div class="input-form" >
            <div class="input-row">
                <div class="field">
                    <label>New Password:</label>
                    <input type="text" class="fake-password" [(ngModel)]="newPassword1" />
                </div>
            </div>
            <div class="input-row">
                <div class="field">
                    <label>Confirm Password:</label>
                    <input type="text" class="fake-password" [(ngModel)]="newPassword2" />
                </div>
            </div>
        </div>
        <div class="error w-100">
            {{error}}
        </div>
    </div>
    <div class="footer">
        <button mat-flat-button color="accent" (click)="dismiss()">Cancel</button>
        <button mat-flat-button color="primary" [disabled]="!canSubmit()" (click)="submit()">Submit</button>
    </div>
</div>