import { Directive, HostListener } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CanDeactivateComponent } from './can-deactivate';
import { CanDeactivateNotification } from './deactivation-service';

@Directive({ selector: 'router-outlet' })
export class RouterOutletCanDeactivatePluginDirective {

  constructor(routerOutlet: RouterOutlet, private canDeactivateService: CanDeactivateNotification) {
    routerOutlet.activateEvents.subscribe( c => this.components.push(c) );
    routerOutlet.deactivateEvents.subscribe( c => {
      const idx = this.components.indexOf(c);
      if (idx > -1) {
        this.components.splice(idx, 1);
      }
    });
  }

  private components: CanDeactivateComponent[] = [];

  @HostListener('window:beforeunload', ['$event'])
  canDeactivate($event: any) {
    for (const component of this.components) {
      if (typeof component.canDeactivate === 'function' && !component.canDeactivate()){
        if (!this.canDeactivateService.canDeactivate()) {
          $event.returnValue = true;
          return;
        }
      }
    }
  }
}
