import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AuthGuard } from './services/authentication/auth-route-guard';
import { TfaRouteGuard } from './services/authentication/tfa-route-guard';
import { SignInComponent } from './sign-in/sign-in.component';
import { TwoFactorComponent } from './sign-in/two-factor/two-factor.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'sign-in',
    pathMatch: 'full',
  },
  {
    path: 'sign-in',
    component: SignInComponent,
    children: [
      {
        path: 'verify',
        component: TwoFactorComponent,
        canActivate: [TfaRouteGuard]
      },
    ]
  },
  {
    path: 'app',
    loadChildren: () => import('./app-home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
