import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { Observable, Subject } from 'rxjs';

import { AuthenticationService } from './services/authentication/authentication.service';
import { DialogConfig, DialogButtonStyle, DialogOption } from './shared/ui/dialog/dialog-options';
import { DialogComponent } from './shared/ui/dialog/dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  static snackBar: MatSnackBar;
  static dialog: MatDialog;

  private static showLoadingSubject = new Subject<any>();
  private static  hideLoadingSubject = new Subject<any>();

  constructor(
    private authenticationService: AuthenticationService,
    private sb: MatSnackBar,
    private dg: MatDialog,
  ) {
    AppComponent.dialog = dg;
    AppComponent.snackBar = sb;
  }

  public static showSnack( message: string, actionLabel: string, durationMs: number = null ) {
    if ( durationMs ) {
      AppComponent.snackBar.open( message, actionLabel, { duration: durationMs } );
    } else {
      AppComponent.snackBar.open( message, actionLabel );
    }
  }

  public static showMessage( title: string, message: string, options: string [],
                             showClose: boolean = true, width: string = '500px', height: string = '300px', ) {
    const dialogOptions = new DialogConfig();
    dialogOptions.showClose = showClose;
    dialogOptions.title = title;
    dialogOptions.message = message;
    dialogOptions.width = width;
    dialogOptions.height = height;
    if ( !options ) {
      options = [ 'OK' ];
    }
    options.forEach( (o) => {
      let style = DialogButtonStyle.Normal;
      if ( Array.from( ['yes', 'confirm', 'save', 'continue', ]).indexOf(o.toLowerCase()) !== -1 ) {
        style = DialogButtonStyle.Confirm;
      } else if ( Array.from( ['no', 'cancel', 'delete' ]).indexOf(o.toLowerCase()) !== -1) {
        style = DialogButtonStyle.Cancel;
      }
      dialogOptions.options.push( new DialogOption( o, o, style));
    });
    return this.showDialog(dialogOptions);
  }

  public static showError( error: any ) {
    console.error(error);
    this.showMessage( 'Error', AppComponent.errorMessage(error), ['OK'] );
  }

  public static showDialog( dialogConfig: DialogConfig ): MatDialogRef<DialogComponent> {
    return AppComponent.dialog.open( DialogComponent, {
      height: dialogConfig.height,
      width: dialogConfig.width,
      data: dialogConfig,
    });
  }

  public static errorMessage( error: any ): string {
    if (typeof error === 'string') {
      return String(error);
    }
    return error.error?.msg ??
           error.message ??
           error.error?.message ??
           error.toString();
  }

  public static showLoading(message = '') {
    this.showLoadingSubject.next(message);
  }

  public static hideLoading() {
    this.hideLoadingSubject.next();
  }

  public static showLoadingObservable(): Observable<any> {
    return this.showLoadingSubject.asObservable();
  }

  public static hideLoadingObservable(): Observable<any> {
    return this.hideLoadingSubject.asObservable();
  }

  public static getSubroute( route: ActivatedRoute ): string {
    if (route.firstChild) {
      return this.getSubroute(route.firstChild);
    } else {
      return route.snapshot.paramMap.get('subroute');
    }
  }
}
