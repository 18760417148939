<div class="content">
    <div class="title">Two-factor Authentication</div>
    <input #codeElement class="code" placeholder="Code"type="text" pattern="\d*" maxlength="6" [(ngModel)]="tfaCode" (keyup.enter)="verifyCode()"/>
    <div class="message">
        Enter the code sent to your email/phone.
    </div>
    <button mat-flat-button color="primary" [disabled]="!canVerify()" (click)="verifyCode()">Verify</button>

    <div class="cancel-div">
        <button mat-flat-button (click)="cancel()">Cancel</button>
        <!-- <button mat-flat-button color="accent" (click)="resend()">Resend</button> -->
    </div>
    <div class="error">{{error}}</div>
</div>