import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AuthGuard } from './services/authentication/auth-route-guard';
import { SharedModule } from './shared/shared.module';
import { SignInComponent } from './sign-in/sign-in.component';
import { TwoFactorComponent } from './sign-in/two-factor/two-factor.component';
import { TfaRouteGuard } from './services/authentication/tfa-route-guard';
import { RouterOutletCanDeactivatePluginDirective } from './deactivation/router-deactivate-directive';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AdminRouteGuard } from './services/authentication/admin-route-guard';

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    TwoFactorComponent,
    RouterOutletCanDeactivatePluginDirective
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    SharedModule,
    MatSnackBarModule,
  ],
  providers: [AuthGuard, TfaRouteGuard, AdminRouteGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
