import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppComponent } from '../app.component';

import { AuthenticationService } from '../services/authentication/authentication.service';
import { IoService } from '../services/io/io.service';
import { ChangePasswordComponent } from '../shared/change-password/change-password.component';
import { SignInOptions } from './sign-in-options';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  title = 'Red Fire Manifold';
  devLabel = '';
  password = '';
  error;
  signInOptions: SignInOptions;
  signingIn = true;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private ioService: IoService,
  ) {
    this.loadLocalSignInOptions();
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.signingIn = (event.url.indexOf( 'sign-in/verify') === -1);
      });
  }

  ngOnInit() {
    this.error = null;
    if (!environment.production) {
      this.devLabel = 'DEVELOPMENT';
    }
    this.authenticationService.isSignedIn()
    .then((isSignedIn) => {
      if (isSignedIn) {
        this.router.navigate(['app']);
      } else {
        this.activatedRoute.queryParams
          .subscribe(params => {
            if (params.code) {
              this.initiatePasswordRecovery(params.code);
            }
          });
      }
    });
  }

  loadLocalSignInOptions() {
    const options = localStorage.getItem('signInOptions');
    if (options) {
      try {
        this.signInOptions = JSON.parse(options);
      } catch (e) {
        this.clearLocalSignInOptions();
      }
    } else {
      this.signInOptions = new SignInOptions();
    }
  }

  saveLocalSignInOptions() {
    localStorage.setItem( 'signInOptions', JSON.stringify(this.signInOptions));
  }

  clearLocalSignInOptions() {
    localStorage.removeItem('signInOptions');
    this.signInOptions = new SignInOptions();
  }

  doSignIn() {
    this.error = null;
    this.saveLocalSignInOptions();
    this.authenticationService.signin(this.signInOptions.identity, this.password)
      .then(() => {
        this.password = '';
        this.router.navigate(['sign-in/verify']);
      })
      .catch(signinError => {
        this.error = signinError.error.msg;
      });
  }

  async forgotPassword() {
    if (!this.signInOptions.identity) {
      AppComponent.showSnack('Enter an email address to recover account', 'OK', 3000);
      return;
    }
    this.ioService.post('/authentication/recoverPassword', {email: this.signInOptions.identity})
    .then(() => {
      AppComponent.showMessage('Check Email', 'If this account exists, a link will be sent via email', ['OK']);
    })
    .catch((err) => {
      AppComponent.showMessage('Error', AppComponent.errorMessage(err), ['OK']);
    });
  }

  async initiatePasswordRecovery(code: string) {
    const confirmResult: any = await this.ioService.post('/authentication/confirmPasswordRecovery', {code});
    if (confirmResult.expired) {
      AppComponent.showSnack('Recovery expired. Please use the link within five minutes.', 'OK', 3000);
      return;
    }
    if (!confirmResult.valid || !confirmResult.token) {
      AppComponent.showSnack('Recovery invalid. Please try again.', 'OK', 3000);
    }

    try {
      await this.authenticationService.setPasswordResetTfaToken(confirmResult.token);
      const passwordDialog = ChangePasswordComponent.showDialog(true);
    } catch {
      AppComponent.showSnack('An error occurred. Please try again.', 'OK', 3000);
    }
  }
}
