import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit, OnDestroy {

  @Input() manualTrigger = false;
  @Input() showLoading = false;
  @Input() message = '';

  showSub: Subscription;
  hideSub: Subscription;

  constructor( ) {}

  ngOnInit() {
    if (!this.manualTrigger) {
      this.subscribe();
    }
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  subscribe() {
    this.unsubscribe();
    this.showSub = AppComponent.showLoadingObservable().subscribe((message) => {
      this.message = message;
      this.showLoading = true;
    });
    this.hideSub = AppComponent.hideLoadingObservable().subscribe( () => {
      this.showLoading = false;
    });
  }

  unsubscribe() {
    if (this.showSub) {
      this.showSub.unsubscribe();
      this.showSub = null;
    }
    if (this.hideSub) {
      this.hideSub.unsubscribe();
      this.hideSub = null;
    }
  }
}
