import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ValidationMessage } from './validation-message';

@Component({
  selector: 'app-form-validation',
  templateUrl: './form-validation.component.html',
  styleUrls: ['./form-validation.component.scss'],
})
export class FormValidationComponent implements OnInit {

  @Input() messages: Array<ValidationMessage>;
  @Input() controlName: string;
  @Input() controlGroup: FormGroup;

  formControl: AbstractControl;

  constructor() { }

  ngOnInit() {
    this.formControl = this.controlGroup.get(this.controlName);
  }
}
