import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent implements OnInit {

  @Input() checked = false;
  @Input() control: AbstractControl;

  @Output() valueChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
    if (this.control) {
      this.checked = this.control.value;
      this.control.valueChanges.subscribe((newValue) => {
        this.checked = newValue;
      });
    }
  }

  toggle() {
    this.checked = !this.checked;
    this.valueChanged.emit(this.checked);
    if (this.control) {
      this.control.setValue(this.checked);
      this.control.markAsDirty();
    }
  }
}
