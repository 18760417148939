import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
  selector: 'app-two-factor',
  templateUrl: './two-factor.component.html',
  styleUrls: ['./two-factor.component.scss']
})
export class TwoFactorComponent implements OnInit, AfterViewInit {

  @ViewChild('codeElement') codeElement: ElementRef;

  tfaCode = '';
  verifying = false;
  error = '';

  constructor( private router: Router,
               private authenticationService: AuthenticationService ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.codeElement?.nativeElement.focus();
  }

  cancel() {
    this.router.navigate(['sign-in']);
  }

  resend() {
    // TODO
  }

  canVerify(): boolean {
    return (!this.verifying) && ((this.tfaCode?.toString().length) === 6);
  }

  verifyCode() {
    this.error = '';
    if (!this.canVerify()) { return; }

    this.verifying = true;

    this.authenticationService.verifyTfaCode(this.tfaCode)
    .then(() => {
      this.router.navigate(['app']);
    })
    .catch((err) => {
      this.error = err.error.msg;
    })
    .finally(() => this.verifying = false);
  }
}
