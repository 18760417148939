import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor( private authenticationService: AuthenticationService,
                 private router: Router ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return new Promise<any>((resolve) => {
            this.authenticationService.isSignedIn()
            .then((isSignedIn) => {
                if (!isSignedIn) {
                    resolve(this.router.parseUrl('/sign-in'));
                } else {
                    resolve(true);
                }
            });
        });
    }
}
