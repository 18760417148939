import { Observable, Subject } from 'rxjs';
import { IoService } from '../services/io/io.service';
import { LoginDetails } from './login-details';

export class APPGLOBAL {
    public static loginDetails: LoginDetails;

    public static databaseTypes: any[];
    public static serviceConnections: any[];
    public static applications: any[];

    public static tokenizerServers = [
      {
        url: 'https://matchbox.rftapi.com/',
        ip: '3.22.164.100'
      },
      {
        url: 'https://wick.rftapi.com/',
        ip: '18.218.61.149'
      }
    ];

    public static reportingServers = [
      'https://quartz.redfirereporting.com/'
    ];

    private static databaseTypesSubject = new Subject<any[]>();
    public static databaseTypesUpdated: Observable<any[]> = APPGLOBAL.databaseTypesSubject.asObservable();

    private static serviceConnectionsSubject = new Subject<any[]>();
    public static serviceConnectionsUpdated: Observable<any[]> = APPGLOBAL.serviceConnectionsSubject.asObservable();

    private static applicationsSubject = new Subject<any[]>();
    public static applicationsUpdated: Observable<any[]> = APPGLOBAL.applicationsSubject.asObservable();

    public static async updateDatabaseTypesAndConnections(ioService: IoService) {
      ioService.post('/database/getDatabaseTypes')
      .then((types) => {
        APPGLOBAL.databaseTypes = types;
        this.databaseTypesSubject.next(types);
      });

      ioService.post('/database/getServiceConnections')
      .then((connections) => {
        APPGLOBAL.serviceConnections = connections;
        this.serviceConnectionsSubject.next(connections);
      });

      ioService.post('/database/getApplications')
      .then((applications) => {
        APPGLOBAL.applications = applications;
        this.applicationsSubject.next(applications);
      });
    }
}
