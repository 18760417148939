import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { APPGLOBAL } from 'src/app/interfaces/app-global';
import { LoginDetails } from 'src/app/interfaces/login-details';
import { IoService } from '../io/io.service';
import { AppComponent } from 'src/app/app.component';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private jwtHelper: JwtHelperService = new JwtHelperService();

  constructor(
    private http: HttpClient,
    private router: Router,
    private ioService: IoService,
  ) {
    this.getStoredLoginDetails();
  }

  private async getStoredLoginDetails() {
    const details = localStorage.getItem('loginDetails');
    if (details) {
      try {
        APPGLOBAL.loginDetails = Object.assign( new LoginDetails(), JSON.parse(details) );
        const user = await this.getAuthenticatedUser();
        if ( null !== user) {
          APPGLOBAL.loginDetails.user = user;
        } else {
          this.router.navigate(['sign-in']);
        }
      } catch (err) {
        AppComponent.showError(err);
        this.removeStoredLoginDetails();
      }
    }
  }

  private async updateLoginToken(token: string) {
    APPGLOBAL.loginDetails = new LoginDetails();
    APPGLOBAL.loginDetails.token = token;

    try {
      APPGLOBAL.loginDetails.user = await this.getAuthenticatedUser();
      if ( null != APPGLOBAL.loginDetails.user ) {
        localStorage.setItem('loginDetails', JSON.stringify(APPGLOBAL.loginDetails));
      } else {
        this.router.navigate(['sign-in']);
      }
    } catch (err) {
      AppComponent.showError(err);
    }
  }

  private removeStoredLoginDetails() {
    localStorage.removeItem('loginDetails');
    APPGLOBAL.loginDetails = null;
  }

  async isSignedIn() {
    if (!(APPGLOBAL.loginDetails?.token)) {
      await this.getStoredLoginDetails();
    }
    if (!APPGLOBAL.loginDetails?.token) { return false; }
    if (this.jwtHelper.isTokenExpired(APPGLOBAL.loginDetails.token)) { return false; }
    const decodedToken = this.jwtHelper.decodeToken(APPGLOBAL.loginDetails.token);
    if (decodedToken.userId) { return true; }

    return false;
  }

  async isTfaTokenValid() {
    if (!(APPGLOBAL.loginDetails?.token)) {
      await this.getStoredLoginDetails();
    }
    if (!APPGLOBAL.loginDetails?.token) { return false; }
    if (this.jwtHelper.isTokenExpired(APPGLOBAL.loginDetails.token)) { return false; }
    const decodedToken = this.jwtHelper.decodeToken(APPGLOBAL.loginDetails.token);
    if (decodedToken.tfa?.userId) { return true; }

    return false;
  }

  getUserId() {
    return APPGLOBAL.loginDetails?.user?._id;
  }

  signin(identity: string, password: string) {
    return new Promise<void>((resolve, reject) => {
      this.http.post<any>(environment.configApiUrl + '/authentication/signIn', {
        identity,
        password
      }, {}).toPromise()
      .then(async (signinResponse) => {
        if (signinResponse.token) {
          await this.updateLoginToken(signinResponse.token);
          resolve();
        } else {
          console.error('login succeeded but did not return a token');
          reject();
        }
      })
      .catch(err => {
        console.error(err);
        reject(err);
      });
    });
  }

  verifyTfaCode(code: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.ioService.post('/authentication/verifyTwoFactor', {
        tfaCode: code,
      })
      .then(async (verifyResponse) => {
        if (verifyResponse.token) {
          await this.updateLoginToken(verifyResponse.token);
          resolve();
        } else {
          console.error('Verify TFA succeeded but did not return a token.');
          reject();
        }
      })
      .catch(err => {
        console.error(err);
        reject(err);
      });
    });
  }

  signout() {
    this.removeStoredLoginDetails();
    this.router.navigate(['/sign-in']);
  }

  setPassword(password: string, key: string) {
    console.log('setPassword: ', password);
    return new Promise<void>((resolve, reject) => {
      this.http.post<any>(environment.configApiUrl + '/authentication/setPassword', {
        password,
        key
      }, {}).toPromise()
        .then(resetResponse => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  async setPasswordResetTfaToken(token: string) {
      this.updateLoginToken(token);
  }

  async getAuthenticatedUser(): Promise<any> {
    try {
      return await this.ioService.post('/user/getAuthenticatedUser');
    } catch (err) {
    }
    return null;
  }

  async getUserAccessLevel(): Promise<number> {
    try {
      const user = await this.getAuthenticatedUser();
      return user.accessLevel || 0;
    } catch (err) {
      AppComponent.showError(err);
    }
    return 0;
  }
}
