import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AdminRouteGuard implements CanActivate {
    constructor( private authenticationService: AuthenticationService,
                 private router: Router ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return new Promise<any>((resolve) => {
            this.authenticationService.isSignedIn()
            .then((isSignedIn) => {
                if (!isSignedIn) {
                    resolve(this.router.parseUrl('/sign-in'));
                } else {
                    this.authenticationService.getUserAccessLevel()
                    .then((level) => {
                        if (level === 3 ) {
                            resolve(true);
                        } else {
                            AppComponent.showSnack('Permission denied.', 'OK', 2000);
                            resolve(this.router.parseUrl('/app'));
                        }
                    });
                }
            });
        });
    }
}
