import { Component, EventEmitter, Injectable, InjectionToken, Output } from '@angular/core';

export const SearchInterfaceToken = new InjectionToken<SearchDetailComponent>('SearchInterfaceToken');

@Component({
    template: '',
    outputs: ['newItemCreated'],
})
export abstract class SearchDetailComponent {
    newItemCreated: EventEmitter<any>;
    setItem(item: any) {}
    canSave(): boolean { return false; }
    newItem() {}

    constructor() {
        this.newItemCreated = new EventEmitter<any>();
    }
}
