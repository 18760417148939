import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { APPGLOBAL } from 'src/app/interfaces/app-global';

@Injectable({
  providedIn: 'root'
})
export class IoService {

  constructor(
    private http: HttpClient) { }

  post(path: string, postData = {}) {
    return new Promise<any>((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if (APPGLOBAL.loginDetails?.token) {
        httpOptions.headers = httpOptions.headers.append('authorization', 'Bearer ' + APPGLOBAL.loginDetails.token);
      }

      this.http.post<any>(environment.configApiUrl + path, postData, httpOptions).toPromise()
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
    });
  }

  upload(path: string, postData, file) {
    // Build FormData object
    const formData = new FormData();
    formData.append('file', file);
    formData.append('data', JSON.stringify(postData));

    // Define post headers
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('token')
    });

    return this.http.post<any>(environment.configApiUrl + path, formData, {
      headers,
      reportProgress: true,
      observe: 'events'
    });
  }

  download(path: string, postData = {}, filename: string = 'report.csv') {
    return new Promise<void>((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + APPGLOBAL.loginDetails.token
      });

      this.http.post<any>(environment.configApiUrl + path, postData, {
        headers,
        responseType: 'blob' as 'json'
      })
        .toPromise()
        .then(response => {
            const saveByteArray = (function() {
                const a = document.createElement('a');
                document.body.appendChild(a);
                return function(data, name) {
                    const blob = new Blob(data, {type: 'octet/stream'});
                    const url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = name;
                    a.click();
                    window.URL.revokeObjectURL(url);
                };
            }());
            saveByteArray([response], filename);

            resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
