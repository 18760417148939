<div class="container">
    <div class="header h2" [class.no-content]="!title">
        {{title}}
    </div>
    <div class="content">
        <mat-grid-list [cols]="showSearch ? 6 : 14" rowHeight="2:1" gutterSize="10px">
            <mat-grid-tile *ngIf="showSearch" [class.warning]="warning">
                <div *ngIf="!creatingNew" class="panel-content">
                    <app-loading [manualTrigger]="true" [showLoading]="loading"></app-loading>
                    <div class="search">
                        <input #input="ngModel" placeholder="Search" [(ngModel)]="searchText">
                        <fa-icon [icon]="faCircle" primaryColor="black" (click)="clearSearch()"></fa-icon>
                    </div>
                    <mat-selection-list *ngIf="itemCount > 0" #selectionList [multiple]="false">
                        <mat-list-option *ngFor="let item of filteredActiveItems; let idx = index" [value]="item" (click)="selectItem(item)" [selected]="isItemSelected(item)" [class.highlight]="idx%2 === 0">
                            <div>{{item.label}}</div>
                            <div class="subtext">{{item.subtext}}</div>
                        </mat-list-option>
                        <mat-divider *ngIf="filteredInactiveItems?.length > 0"></mat-divider>
                        <h1 class="h1 subheader" *ngIf="filteredInactiveItems?.length > 0" matSubheader>Inactive</h1>
                        <mat-list-option class="inactive-item" *ngFor="let item of filteredInactiveItems; let idx = index" [value]="item" (click)="selectItem(item)" [selected]="isItemSelected(item)" [class.highlight]="idx%2 === 0">
                            <div>{{item.label}}</div>
                            <div class="subtext">{{item.subtext}}</div>
                        </mat-list-option>
                    </mat-selection-list>
                    <div *ngIf="itemCount === 0" class="no-records">
                        <div>
                            (None)
                        </div>
                    </div>
                    <div *ngIf="showAdd" class="search-footer" (click)="new()">
                        <button mat-flat-button>New +</button>
                    </div>
                </div>
                <div *ngIf="creatingNew" class="new-content">
                    <div>
                        (Creating New Record)
                    </div>
                    <button mat-flat-button (click)="cancelNew()">Cancel</button>
                </div>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="!showSearch" (click)="showSearchClicked()" [class.warning]="warning">
                <div class="back-search-div">
                    <fa-icon [icon]="faSearch" primaryColor="black" class="fa-3x"></fa-icon>
                </div>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="showSearch ? 5 : 13" [class.warning]="warning">
                <div class="panel-content">
                    <div class="detail">
                        <ng-content select="[search-item]"></ng-content>
                    </div>
                </div>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
</div>
