import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppComponent } from 'src/app/app.component';
import { IoService } from 'src/app/services/io/io.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  isTfa = false;

  newPassword1 = '';
  newPassword2 = '';

  error = '';

  submitting = false;

  constructor( @Inject(MAT_DIALOG_DATA) public data: any,
               public dialogRef: MatDialogRef<ChangePasswordComponent>,
               public ioService: IoService ) {
    this.isTfa = data;
  }

  public static showDialog(tfa = false) {
    return AppComponent.dialog.open(ChangePasswordComponent, {
      data: tfa,
    });
  }

  ngOnInit(): void {
  }

  dismiss() {
    if ( this.dialogRef ) {
      this.dialogRef.close();
    }
  }

  submit() {
    this.error = '';
    if (!this.validatePasswords()) { return; }
    this.submitting = true;
    const url = this.isTfa ? '/authentication/tfaChangePassword' : '/authentication/changePassword';
    this.ioService.post(url, {password: this.newPassword1})
    .then(() => {
      AppComponent.showSnack('Password updated.', 'OK', 2000);
      this.dismiss();
    })
    .catch((err) => {
      AppComponent.showMessage('Error', AppComponent.errorMessage(err), ['OK']);
    })
    .finally(() => {
      this.submitting = false;
    });
  }

  canSubmit(): boolean {
    if (!this.newPassword1.trim()) {
      return false;
    }
    if (!this.newPassword2.trim()) {
      return false;
    }
    return true;
  }

  validatePasswords(): boolean {
    if (this.newPassword1 !== this.newPassword2) {
      this.error = 'Passwords do not match.';
      return false;
    }
    return true;
  }
}
