<div *ngIf="signingIn" class="sign-in-form">
    <div class="dev-label">{{devLabel}}</div>
    <div class="title">{{title}}</div>
    <input type="email" placeholder="email" [(ngModel)]="signInOptions.identity" />
    <input type="password" placeholder="password" [(ngModel)]="password" />
    <div class="checkbox-div">
        <div class="forgot-pw" (click)="forgotPassword()">Forgot Password</div>
        <mat-checkbox [(ngModel)]="signInOptions.keepSignedIn"
                    color="primary"
                    labelPosition="before">
            Remember Me
        </mat-checkbox>
    </div>
    <button mat-flat-button color="primary" (click)="doSignIn()">Sign In</button>
    <div class="error">{{error}}</div>
</div>

<router-outlet></router-outlet>