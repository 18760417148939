import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { DialogComponent } from './ui/dialog/dialog.component';
import { FormValidationComponent } from './ui/form-validation/form-validation.component';
import { SearchComponent } from './search/search.component';
import { SampleSearchComponent } from './search/sample-search/sample-search.component';
import { SearchItemDirective } from './search/search-item-directive';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingComponent } from './loading/loading.component';
import { ToggleComponent } from './ui/toggle/toggle.component';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [
    DialogComponent,
    FormValidationComponent,
    SearchComponent,
    SampleSearchComponent,
    SearchItemDirective,
    ChangePasswordComponent,
    LoadingComponent,
    ToggleComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    MatButtonModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatInputModule,
    MatIconModule,
    MatDialogModule,
    MatGridListModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatButtonToggleModule,

    FontAwesomeModule,
    NgxMaskModule.forRoot()
  ],
  exports: [
    FontAwesomeModule,
    MatButtonModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatInputModule,
    MatIconModule,
    MatGridListModule,
    MatListModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,

    FormValidationComponent,
    SearchComponent,
    SampleSearchComponent,
    SearchItemDirective,
    ChangePasswordComponent,
    LoadingComponent,
    ToggleComponent,
    NgxMaskModule,
  ],
  providers: [
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {disableClose: true, hasBackdrop: true}},
  ]
})
export class SharedModule { }
