import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class TfaRouteGuard implements CanActivate {
    constructor( private authenticationService: AuthenticationService,
                 private router: Router ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.authenticationService.isTfaTokenValid()) {
            return this.router.parseUrl('/sign-in');
        } else {
            return true;
        }
    }
}
